export const filterLabel = (value, dictType) => {
  if (!value) {
    return ''
  }
  const correctItem = AllDict[dictType].filter((item, index) => {
    return value === item.value
  })
  return correctItem && correctItem.length > 0 ? correctItem[0].label : '-'
}
export const filterLabelEmpty = (value, dictType) => {
  if (!value) {
    return ''
  }
  const correctItem = AllDict[dictType].filter((item, index) => {
    return value === item.value
  })
  return correctItem && correctItem.length > 0 ? correctItem[0].label : ''
}

export const AllDict = {
  paymentDict: [
    {
      value: 1,
      label: '未付款'
    },
    {
      value: 2,
      label: '已付款'
    },
    {
      value: 3,
      label: '已退款'
    },
    {
      value: 4,
      label: '已取消'
    }
  ],
  orderStatusDict: [
    {
      value: 1,
      label: '未完成'
    },
    {
      value: 2,
      label: '已完成'
    },
    {
      value: 3,
      label: '退货退款'
    },
    {
      value: 4,
      label: '换货'
    },
    {
      value: 5,
      label: '仅退款'
    },
    {
      value: 6,
      label: '已取消'
    }
  ],
  expressStateDict: [
    {
      value: 1,
      label: '未发货'
    },
    {
      value: 2,
      label: '已发货'
    },
    {
      value: 3,
      label: '已派送'
    },
    {
      value: 4,
      label: '因原因出现错误'
    },
    {
      value: 5,
      label: '待取件'
    },
    {
      value: 6,
      label: '已收件'
    },
    {
      value: 7,
      label: '已签收'
    }
  ],
  onlineStateDict: [
    {
      value: '1',
      label: '已上架'
    },
    {
      value: '2',
      label: '已下架'
    },
    {
      value: '3',
      label: '待上架'
    }
  ],
  // 顺丰快递节点状态码
  // 根据以下备注生成状态码：
  sfExpressStateDict: [
    {
      value: '50',
      label: '顺丰已收件'
    },
    {
      value: '51',
      label: '一票多件的子件'
    },
    {
      value: '54',
      label: '上门收件'
    },
    {
      value: '80',
      label: '派送中'
    },
    {
      value: '8000',
      label: '已签收'
    }
  ],
  regionDict: [
    {
      value: 1,
      label: '支持全国发货'
    },
    {
      value: 2,
      label: '本地发货'
    }
  ],
  discount: [
    {
      value: 1,
      label: '允许会员价'
    },
    {
      value: 2,
      label: '不允许'
    }
  ],
  isDict: [
    {
      value: 1,
      label: '不是'
    },
    {
      value: 2,
      label: '是'
    }
  ],
  shopTypes: [
    {
      value: '全部',
      label: '全部'
    },
    {
      value: '滤材',
      label: '滤材'
    },
    {
      value: '器材',
      label: '器材'
    },
    {
      value: '水剂',
      label: '水剂'
    },
    {
      value: '饲料',
      label: '饲料'
    }
  ]
}

const baseURL = 'https://www.ipolarx.com/api/jf-tech/v1'

// 通用下载方法
export const download = (fileName) => {
  window.location.href = baseURL + '/download?fileName=' + encodeURI(fileName)
}

// 通用下载方法
export const downloadLog = (fileName) => {
  window.location.href = baseURL + '/deviceLogDown?name=' + encodeURI(fileName)
}

export const downloadFile = (response) => {
  // 获取文件名
  var filename = ''
  var disposition = response.headers['content-disposition']
  if (disposition && disposition.indexOf('attachment') !== -1) {
    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    var matches = filenameRegex.exec(disposition)
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '')
    }
  }

  // 创建一个临时的下载链接
  var blob = new Blob([response.data], { type: 'application/octet-stream' })
  var url = window.URL.createObjectURL(blob)

  // 创建一个虚拟的下载链接并触发点击事件来下载文件
  var link = document.createElement('a')
  link.href = url
  link.download = decodeURIComponent(filename)
  link.click()

  // 清理临时下载链接
  window.URL.revokeObjectURL(url)
}
